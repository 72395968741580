document.addEventListener('turbolinks:load', function() {

  $('body').on('change', '.new_component', function() {
    let type = $( this ).val();
    let targetAttribute = $(this).attr('id');
    let projectID = $('.project-title').attr('data-id');


    $.ajax({
      url: `/projects/${projectID}/components/new`,
      method: 'GET',
      dataType: 'script',
      data: { type: type, target: targetAttribute },
      success: function(data) {

      }
    })
  })

  $('body').on('click', '#addComponent', function() {
    let componentIndex = $('.new_component').length;
    let newComponent = $( '#component_selector' ).clone();
    newComponent.attr('id', `component_selector_${componentIndex}`);

    $('.form-container').append(newComponent);
  })
})