
const validateContactUs = () => {
  let contactUsForm = $('#contactUsForm');
  let formName = $('#contact_us_name');
  let formEmail = $('#contact_us_email');
  let formPhone = $('#contact_us_phone');
  let formMessage = $('#contact_us_message');

  removeErrorMessage(contactUsForm);

  contactUsForm.on('submit', function(e) {
    let formErrors = false;

    formErrors = errorMessage(formName);
    formErrors = errorMessage(formEmail);
    formErrors = errorMessage(formPhone);
    formErrors = errorMessage(formMessage);
    if ( formErrors) {
      e.preventDefault();
      return false ;
    }
  });
}

const errorMessage = (inputElement) => {
  if ( inputElement.val() ) {
    if ( inputElement.is('#contact_us_phone') && inputElement.val().match(/[a-z]/i) ) {
      if (inputElement.siblings('.text-orange').length == 0) {
        inputElement.parent().append(`<span class='text-orange'>Please enter a phone number</span>`);
        inputElement.addClass('error');
      }
      return true;
    }
    return false;
  } else {
    inputElement.addClass('error');
    if (inputElement.siblings('.text-orange').length == 0) {
      if ( inputElement.is('#contact_us_phone') ) {
        inputElement.parent().append(`<span class='text-orange'>Please enter a phone number</span>`);
      } else {
        inputElement.parent().append(`<span class='text-orange'>This field can't be empty</span>`);
      }

    }
    return true
  }
}

const removeErrorMessage = (form) => {
  form.find('input').on('blur', function() {
    if ( $(this).val() ) {
      $( this ).removeClass('error');
      $( this ).siblings('span.text-orange').remove();
    }
  })
}

export { validateContactUs }