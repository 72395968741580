function toggleNavIcon() {
  document.querySelector('.navbar-toggler').addEventListener('click', function(e) {
    if (this.classList.contains('collapsed')) {
      document.querySelector('.nav-closed-icon').style.display = 'none';
      document.querySelector('.nav-opened-icon').style.display = 'inline-block';
    } else {
      document.querySelector('.nav-closed-icon').style.display = 'block';
      document.querySelector('.nav-opened-icon').style.display = 'none';
    }
  });
}

export { toggleNavIcon }