import barba from '@barba/core';
import * as Cursor from './cursor';
import * as ContactUs from './contact_us';
import * as NavBar from './navbar';

document.addEventListener('turbolinks:load',function() {

  barba.init({
    name: 'a8-animation',
    transitions: [{
      from: {
        custom: ({trigger}) => {
          return trigger.classList && !trigger.classList.contains('no-animation');
        }
      },

      to: {
        namespace: [
          'projects',
          'landing_pages',
          'blogs',
          'webs',
          'brandings',
        ]
      },

      async leave(data) {
        const done = this.async();
        gsap.to('#blank-loading-page', { x: '0%', duration: 0.500})
        await delay(500);
        done();
      },

      async enter(data) {
        const done = this.async();
        $('body').css('overflow','hidden');
        transitionStart();
        await delay(2000);
        transitionEnd();
        $('body').css('overflow', '')
        done();
      },

      async once(data) {
        const done = this.async();
        transitionStart();
        await delay(2000);
        transitionEnd();
        done();
      }
    }]
  });

  barba.hooks.after(()=> {
    init();
  })

  init();

});

function transitionStart() {
  let gif = $('.loading-page').find('img');
  $('.loading-page img').remove();
  $('.loading-page').append(gif);
  gsap.to('.loading-page', { x: '0%', display: 'flex', duration: 0});
  gsap.to('#blank-loading-page', { x: '100%', duration: 1});
  $('.animate__fadeInUp').removeClass('animate__fadeInUp');
  $('.cursor').hide();
  $('.cursor-dot').hide();
}

function transitionEnd() {
  gsap.to('.loading-page', {x: '-100%' , display: 'none', duration: 1});
  $('.animate__animated').addClass('animate__fadeInUp');
  $('.cursor').show();
  $('.cursor-dot').show();
}

function delay(n) {
  n = n || 2000;
  return new Promise(done => {
    setTimeout(() => {
      done();
    }, n);
  })
}

function init() {
  let projectCarousel = document.querySelector('#projectsCarousel');
  let projectTypeCarousel = document.querySelector('#projectTypeCarousel');
  let blogsCarousel = document.querySelector('#blogsCarousel');

  if (projectCarousel) {
    slickIndexCarousel(projectCarousel);
  }

  if (blogsCarousel) {
    slickIndexCarousel(blogsCarousel);
  }

  if (projectTypeCarousel) {
    intersectionObeserver();
    slickTypeCarousel();
  }

  toggleNaviationBar();
  scrollToProject();
  removeProjectDetailsOnPagination();
  updateBootstrapPaginationCount('#clientsOpinionsCarousel');
  updateBootstrapPaginationCount('#projectLaptopCarousel');
  addAnimations();
  Cursor.setCursor();
  ContactUs.validateContactUs();
  resizelaptopImage();
  scrollToTopForBlogs();
  NavBar.toggleNavIcon();

  window.onresize = function() {
    let laptopImage = document.querySelector('#laptopImage');
    if (laptopImage) {
      let laptopHeight = laptopImage.height;

      let laptopImageWrapper = $('#projectLaptop').find('.image-wrapper');

      if ( window.innerWidth > 600 ) {
        laptopImageWrapper.css(`height`, laptopHeight - (laptopHeight * 0.15));
      } else {
        laptopImageWrapper.css(`height`, laptopHeight - (laptopHeight * 0.20));
      }
    }
  }

}

function resizelaptopImage() {
  let laptopImage = document.querySelector('#laptopImage');

  if ( laptopImage ) {
    let laptopHeight = laptopImage.height;

    let laptopImageWrapper = $('#projectLaptop').find('.image-wrapper');

    if ( window.innerWidth > 600 ) {
      laptopImageWrapper.css(`height`, laptopHeight - (laptopHeight * 0.15));
    } else {
      laptopImageWrapper.css(`height`, laptopHeight - (laptopHeight * 0.20));
    }
  }
}

function removeProjectDetailsOnPagination() {
  $('body').on('click', '.prev-button, .next-button', function() {
    $('#projectDetails').closest('.row.custom-padding').remove();
  })
}

function slickIndexCarousel(carousel) {
  let projectSize = document.querySelector('.slick-center-carousel').dataset.projectSize;
  let slidesToShow = 3;
  projectSize < 3 ? slidesToShow = projectSize : slidesToShow = slidesToShow;
  if ( projectSize < 3 ) {
    carousel.style['grid-template-columns'] = '50%'
  }
  $('.slick-center-carousel').on('init',function() {
    $('.slick-active').addClass('animate__animated animate__fadeInUp');
    $('.slick-active.slick-center').addClass('animate__delay-0_2s')
    $('.slick-active:last').addClass('animate__delay-0_4s');
  }).slick({
    slidesToShow: slidesToShow,
    infinite: true,
    centerMode: true,
    centerPadding: '0px',
    prevArrow: `<div class="prev-button button" role="button" data-slide="prev">
                  <span><</span> Prev
                </div>`,
    nextArrow: `<div class="next-button button" role="button" data-slide="prev">
                  Next<span>></span>
                </div>`,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      }, {
        breakpoint: 992,
        settings: {
          settings: 'unslick',
          vertical: true,
          slidesToShow: projectSize,
        }
    }],
  });
}

function slickTypeCarousel() {
  let projectSize = document.querySelector('#projectTypeCarousel').dataset.projectSize;
  var initialSlide = 0;
  let pathName = document.location.pathname.split('/').pop();
  let thisIsShowPage = true;

  if ( pathName == 'branding' || pathName == 'web') {
    thisIsShowPage = false;
  }

  let indexSettings = {
    settings: 'unslick',
    vertical: true,
    slidesToShow: projectSize,
  }

  let showSettings = {
    settings: 'unslick',
  }

  $('#projectTypeCarousel').on('init', function(event, slick) {
    let addZero = true;
    let slides = $( this ).find('.slick-slide:not(.slick-cloned)');
    let projectId = document.location.pathname.split('/').pop();
    // This projectID will eventually change to project-name since URL will change to the friendly id
    slides.each(function() {
      let slideProjectId = $(this).find('.carousel-container').data('project-id')
      if (projectId == slideProjectId) {
        initialSlide = $(this).data('slick-index');
      }
    })
    slick.slideCount < 10 ? addZero = true : addZero = false;
    let prevButton = $('.prev-button');
    let newElement = $(`<div class='flex-between'>
                          <div class='current-page' id='currentPage'>0${initialSlide +1}</div>/
                          <div class='total-page'>${addZero ? '<span>0</span>' : ''}${slick.slideCount}</div>
                        </div>
    `)
    prevButton.after(newElement);
    $('.slick-button-container').appendTo('#projectTypeCarousel');
      addAnimationToProjectCarousel('add');
    $('.project-image-wrapper').addClass('animated customBounceIn');

  }).slick({
    slidesToShow: 1,
    infinite: true,
    draggable: false,
    speed: 750,
    appendArrows: $('.slick-button-container'),

    prevArrow: `<div class="prev-button button" role="button" data-slide="prev">
                  <span><</span> Prev
                </div>`,
    nextArrow: `<div class="next-button button" role="button" data-slide="prev">
                  Next<span>></span>
                </div>`,
    responsive: [{

      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        infinite: true
      }

    }, {
      breakpoint: 992,
      settings: thisIsShowPage ? showSettings : indexSettings
    }
  ],
  }).slick('slickGoTo', initialSlide, true)
  .slick('slickGoTo', initialSlide, function(e) {
    addAnimationToProjectCarousel('add');
  })
  .on('beforeChange', function() {
    addAnimationToProjectCarousel('remove');
  })
  .on('afterChange', function(event, slick, currentSlide, nextSlide) {
    currentSlide < 10 ? currentSlide = `0${currentSlide + 1}` : currentSlide +1;
    $('#currentPage').text(currentSlide);
    addAnimationToProjectCarousel('add');
  });
}

function scrollToProject() {

  let isThisProjectShow = false;
  let path = window.location.pathname.split('/');
  let pathName = path[path.length - 2]
  if ( pathName == 'branding' || pathName == 'web' ) {
    isThisProjectShow = true;
  }

  if (isThisProjectShow) {
    let topPosition = $('#projectDetails').offset().top;

    if ( window.innerWidth >= 600 ) {
      if (window.pageYOffset > 0 ){
        window.scroll(0,topPosition - 100);
      }
      $('html,body').animate({
      scrollTop: topPosition - 50},
      {
        duration: 'slow',
        complete: function() {
          $( '.navbar' ).css({ 'top': '-5rem', 'padding-bottom': 0});
        },
      });
    } else {
      window.scroll(0,0);
    }
  }
}

function scrollToTopForBlogs() {
  let path = window.location.pathname.split('/');
  let pathName = path[path.length - 2]
  if (pathName == 'blogs') {
    window.scroll(0,0);
  }
}

function toggleNaviationBar() {
  var prevScrollpos = window.pageYOffset;
  var navbar = document.querySelector('.navbar')
  window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;

    if ( prevScrollpos <= 0 ) {
      navbar.style.top = "0";
      navbar.style['padding-bottom'] = '1rem'
    } else if (prevScrollpos > currentScrollPos ) {
      navbar.style.top = "0";
      navbar.style['padding-bottom'] = '1rem'
    } else {
      navbar.style.top = "-5rem";
      navbar.style['padding-bottom'] = '0rem'
      var collapseNavbar = document.querySelector('.navbar-collapse')

      if ( collapseNavbar.classList.contains('show') ) {
        document.querySelector('.navbar-toggler').click();
      }
    }
    prevScrollpos = currentScrollPos;
  }
}

function intersectionObeserver() {
  const images = document.querySelectorAll('.component-container');
  const options = { root: null, rootMargin: '10% 0px', threshold: 0.5}

  var observer = new IntersectionObserver((entries)=> {
    entries.forEach(entry => {

      let componentType = entry.target.children[0];
      if ( entry.intersectionRatio > 0.25 ) {
        if (componentType.classList.contains('triple-picture-component') ||
            componentType.classList.contains('double-picture-component') ||
            componentType.classList.contains('juxtapose-component'))
            {
          entry.target.classList.remove('animated');
          Array.from(componentType.children).forEach(child => {
            child.classList.add('animated', 'fadeInFromBottom');
          });
        } else {
          entry.target.classList.add('animated', 'fadeInFromBottom');
        }
      }
    })
  }, options);

  images.forEach(image => {
    observer.observe(image);
  });
}

function addAnimationToProjectCarousel( addOrRemove ) {

  if ( window.innerWidth >= 600 ) {
    let activeSlide = $('.slick-current');
    let carouselBody = activeSlide.find('.carousel-body');
    let title = carouselBody.find('.title');
    let description = carouselBody.find('.description-wrapper');
    let viewProjectLink = carouselBody.find('.arrow-icon-link');

    if ( addOrRemove == 'add' ) {
      $('.slick-slide').find('.carousel-body').css('opacity','0');
      carouselBody.css('opacity','1')
      title.addClass(['animate__animated', 'animate__fadeInUp']);
      description.addClass(['animate__animated', 'animate__fadeInUp', 'animate__delay-0_4s']);
      viewProjectLink.addClass(['animate__animated', 'animate__fadeInUp', 'animate__delay-1s']);
    } else {
      title.removeClass(['animate__animated', 'animate__fadeInUp']);
      description.removeClass(['animate__animated', 'animate__fadeInUp', 'animate__delay-0_4s']);
      viewProjectLink.removeClass(['animate__animated', 'animate__fadeInUp', 'animate__delay-1s']);
    }
  }
}

function updateBootstrapPaginationCount(carouselId) {
  let carouselContainer = document.querySelector(carouselId);

  if (carouselContainer) {
    let currentPage = carouselContainer.querySelector('.current-page');
    let totalPage = carouselContainer.querySelector('.total-page');
    let carouselItemCount = carouselContainer.dataset.size;

    carouselItemCount < 10 ? carouselItemCount = `0${carouselItemCount}` : carouselItemCount;

    totalPage.innerHTML = carouselItemCount

    $(carouselId).on('slid.bs.carousel', function() {
      let currentIndex = $('.carousel-item.active').index() + 1;

      currentIndex < 10 ? currentIndex = `0${currentIndex}` : currentIndex;
      currentPage.innerHTML = currentIndex;
    });
  }
}

function addAnimations() {
  let bounceIn = document.querySelector('.bounceIn');
  if (bounceIn) {
    bounceIn.classList.add('customBounceIn');
  }
}