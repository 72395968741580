const setCursor = () => {
  cursorDot();
  outerCursor();
}

const cursorDot = () => {
  const cursor = $('.cursor-dot');
  const orangeColor = '#F4772C'
  const blueColor = '#00AEEF'

  $(window)
    .on('mousemove', function(e) {
      cursor.css({
        top: e.clientY - cursor.height() / 2,
        left: e.clientX - cursor.width() / 2
      })
    })
    .on('mouseleave', function() {
      cursor.css({ opacity: "0" })
    })
    .on('mouseenter', function() {
      cursor.css({ opacity: "1" });
    });

  $("a, .prev-button, .next-button")
    .on('mouseenter', function() {
      cursor.css({ transform: "scale(1.5)", backgroundColor: orangeColor });
    })
    .on('mouseleave',function() {
      cursor.css({ transform: "scale(1)", backgroundColor: blueColor });
    });
  $('.nav-link').on('mouseleave',function() {
    cursor.css({ transform: "scale(1)", backgroundColor: blueColor });
  });
}

const outerCursor = () => {
  const updateProperties = (elem, state) => {
    elem.style.setProperty('--x', `${state.x}px`)
    elem.style.setProperty('--y', `${state.y}px`)
    elem.style.setProperty('--width', `${state.width}rem`)
    elem.style.setProperty('--height', `${state.height}rem`)
    elem.style.setProperty('--radius', state.radius)
    elem.style.setProperty('--scale', state.scale)
  }

  document.querySelectorAll('.cursor').forEach(cursor => {
    let onElement

    $(window)
      .on('mouseleave', function() {
        cursor.style.setProperty('opacity', '0');
      })
      .on('mouseenter', function() {
        cursor.style.setProperty('opacity', '1');
      });

    const createState = e => {
      const defaultState = {
        x: e.clientX,
        y: e.clientY,
        width: 3.125,
        height: 3.125,
        radius: '50%'
      }

      const computedState = {}

      if (onElement != null) {
        const { top, left, width, height } = onElement.getBoundingClientRect()
        const radius = window.getComputedStyle(onElement).borderTopLeftRadius

        computedState.x = left + width / 2
        computedState.y = top + height / 2
        computedState.width = width
        computedState.height = height
        computedState.radius = radius
      }

      return {
        ...defaultState,
        ...computedState
      }
    }

    document.addEventListener('mousemove', e => {
      const state = createState(e)
      updateProperties(cursor, state)
    })
  })
}

export { setCursor }