// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("trix");
require("@rails/actiontext");

require("jquery");
window.jQuery = $;
window.$ = $;

require('bootstrap');

require("@rails/activestorage").start();
require("channels");

import 'animate.css/animate.min.css';
import 'slick-carousel/slick/slick.css';
require('slick-carousel');

require('./navbar');
require('./projects');
require('./components');
require('./cursor');



$(document).on('turbolinks:load', function () {
    $('#myTable').DataTable();
});